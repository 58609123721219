import { Spinner } from "components/Elements";
import { ContentLayout } from "components/Layout";

import greetingTime from "greeting-time";
import { useEffect, useState } from "react";
import { ShoppingBag, Users } from "react-feather";
// import { Link } from "react-router-dom";

import { useDashboardAnalytics } from "../api/getAnalytics";
import { Card } from "../components/Card";
// import { ChangeLog } from "../components/ChangeLog";
import { useAuth } from "lib/auth";
import {AiOutlineShopping} from "react-icons/ai"


export const Dashboard = () => {
  const analyticsQuery = useDashboardAnalytics();

  const { user } = useAuth();

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    setGreeting(greetingTime(new Date()));
  }, []);

  if (analyticsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  console.log(analyticsQuery.data);

  if (!analyticsQuery.data) return null;

  return (
    <ContentLayout title="Dashboard">
      {/* <Breadcrumbs /> */}
      <div className="mt-6">
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3">
            <p className="text-gray-500 text-lg font-bold">
              {" "}
              {greeting} 👋🏼 {user.name}
            </p>
            <div className="grid grid-cols-3 gap-4 mt-5">
              <Card
                // link="./ads"
                icon={<AiOutlineShopping className="text-2xl text-blue-700" />}
                content={analyticsQuery.data.ads}
                label="Total Products"
                title="products"
              />
              <Card
                // link="./applications"
                icon={<ShoppingBag className="h-5 text-green-700" />}
                content={analyticsQuery.data.applications}
                label="Total Orders"
                title="orders"

              />
              
              
              <Card
                link="./users"
                icon={<Users className="text-yellow-700" />}
                content={analyticsQuery.data.users}
                label="Users"
                title="users"

              />
            </div>
          </div>
         
        </div>
      </div>
    </ContentLayout>
  );
};
