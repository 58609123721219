import React from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const Card = ({ link, icon, content, label, title }) => {



  return (
    <div className="bg-white rounded-lg shadow-md p-4 text-gray-800 flex items-center gap-5 hover:bg-green-50 hover:text-gray-800  w-full">
      {/* <Link to={link}> */}
      <div className={`flex items-center justify-center w-10 h-10 ${title === "products" ? "bg-blue-100" : title === "orders" ? "bg-green-100" : "bg-yellow-100"  }  rounded-full`} >

        {icon}
      </div>
        <div className="flex flex-col items-start ">
          <div className="font-bold text-2xl">{content}</div>
        <span className="text-xs font-bold">{label}</span>
        </div>
      {/* </Link> */}
    </div>
  );
};

Card.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  content: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  downloads: PropTypes.number,
};
